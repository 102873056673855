body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body.map-selector-active {
  overflow: hidden;
}

.w-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.relative {
  position: relative;
}

.select-none {
  user-select: none;
}

.absolute {
  position: absolute;
}

.p-4 {
  padding: 1rem;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transition-all {
  transition: all 0.3s ease;
}

.w-64 {
  width: 16rem;
}

.w-12 {
  width: 3rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.text-gray-700 {
  color: #4a5568;
}

.block {
  display: block;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.bg-gray-400 {
  background-color: #cbd5e0;
}

.text-white {
  color: #fff;
}

.rounded {
  border-radius: 0.25rem;
}

.hover\:bg-gray-500:hover {
  background-color: #a0aec0;
}

.bg-gray-600 {
  background-color: #718096;
}

.hover\:bg-gray-700:hover {
  background-color: #4a5568;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.mr-2 {
  margin-right: 0.5rem;
}

.custommodal {
  border-radius: 10px;
  padding: 20px;
  background-color: #edf2f7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.map-selector-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.button-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
}

.map-button {
  background-color: #2f3640;
  color: #e84118;
  border: none;
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  font-size: 16px;
}

.map-button:hover {
  background-color: white;
}

.map-button .button-icon {
  margin-right: 8px;
}


#take-photo-button {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

#take-photo-button.visible {
  opacity: 1;
  visibility: visible;
  bottom: 90px;
}

@keyframes toastPop {
  0% {
    transform: translateX(-50%) translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}

#take-photo-button.animate {
  animation: toastPop 0.3s ease-out forwards;
}

.map-selector-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.map-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.take-photo-button {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 80px);
  transform: translateX(-50%);
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bolder;
  font-family: 'Arial', sans-serif;
  z-index: 2;
  border-radius: 4px;
}

#camera-button {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 70px);
  background-color: #4a515d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bolder;
  font-family: 'Arial', sans-serif;
  z-index: 2;
  border-radius: 4px;
}

#cancel-button {
  position: absolute;
  bottom: 10px;
  left: calc(50% + 60px);
  background-color: #FF0000;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bolder;
  font-family: 'Arial', sans-serif;
  z-index: 2;
  border-radius: 4px;
}

#bound-box {
  pointer-events: none;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 640px;
  height: calc(100% - 200px);
  border: 4px solid white;
  z-index: 3;
}

.search-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.photo-preview,
.downloadpdfmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4a515d;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1001;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
  color: white;
}

.downloadpdfmodal {
  width: 80%;
}

.photo-preview img {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2f3640;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1001;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
}

.photo-preview canvas {
  max-width: 100%;
  max-height: calc(90vh - 100px);
  object-fit: contain;
  border-radius: 4px;
  user-select: none;
  -webkit-user-drag: none;
}


.photo-preview-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}



.accept-button:hover,
.retake-button:hover,
.undo-button:hover {
  background-color: #616a79;
}

.accept-button .button-icon,
.retake-button .button-icon,
.undo-button .button-icon {
  margin-right: 8px;
}

.unit-toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.unit-toggle-button {
  background-color: #2f3640;
  border: none;
  border-radius: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  transition: background-color 0.3s;
}

.unit-toggle-button:hover {
  background-color: #404b5c;
}

.unit-toggle-label {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px;
}

.unit-toggle-switch {
  width: 60px;
  height: 24px;
  background-color: #4a515d;
  border-radius: 34px;
  padding: 4px;
  transition: background-color 0.3s;
  position: relative;
}

.unit-toggle-switch.feet {
  background-color: #e84118;
}

.unit-toggle-switch.meters {
  background-color: #44bd32;
}

.unit-toggle-slider {
  width: 23px;
  height: 23px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: transform 0.3s;
  position: absolute;
  left: 4px;
}

.unit-toggle-switch.meters .unit-toggle-slider {
  transform: translateX(26px);
}

.plotting-mode-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 9;
}

.accept-button {
  background-color: #2f3640;
  color: #e84118;
  border: none;
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  font-size: 16px;
  margin: 0 5px;
}

.retake-button {
  background-color: #2f3640;
  color: #e84118;
  border: none;
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  font-size: 16px;
  font-weight: bolder;
  font-family: 'Arial', sans-serif;
  margin: 0 5px;
}

.undo-button {
  background-color: #a48945;
  color: #ffffff;
  border: none;
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  font-size: 16px;
  font-weight: bolder;
  font-family: 'Arial', sans-serif;
  margin: 0 5px;
}

.photo-preview-button:hover {
  background-color: #c23616;
}

.roof-selection-prompt {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 4px;
}

.roof-selection-box {
  position: absolute;
  border: 2px solid #4285F4;
  background-color: rgba(66, 133, 244, 0.3);
  pointer-events: none;
}

.detecting-roof {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.admin-container {
  height: 100vh;
  overflow: auto;
}

#cad-toolbar {
  background-color: #111827 !important;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1000;
}

.toolbar-button {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 8px;
  background-color: #4a515d;
  border: 2px solid #3a4049;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 5px rgba(74, 81, 93, 0.5);
}

.toolbar-button:hover {
  background-color: #5a616d;
  transform: perspective(1px) translateZ(0) scale(1.05);
  box-shadow: 0 0 10px rgba(74, 81, 93, 0.8);
}

.toolbar-button:active {
  transform: perspective(1px) translateZ(0) scale(0.95);
}

.toolbar-button-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bolt-button {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 8px;
  background: linear-gradient(45deg, #d4af37, #f9d77e, #d4af37);
  border: 2px solid #b8860b;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  transform: perspective(1px) translateZ(0);
  box-shadow: 
    inset 0 0 10px rgba(255, 215, 0, 0.5),
    0 0 5px rgba(212, 175, 55, 0.5);
  animation: pulse 2s infinite;
}

.bolt-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transform: rotate(45deg);
  animation: holographic 3s linear infinite;
}

.bolt-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: shimmer 2s linear infinite;
}

.bolt-button:hover {
  transform: perspective(1px) translateZ(0) scale(1.05);
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.8);
}

.bolt-button:active {
  transform: perspective(1px) translateZ(0) scale(0.95);
}

.bolt-button-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes holographic {
  0% {
    left: -50%;
    top: -50%;
  }
  100% {
    left: 150%;
    top: 150%;
  }
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 
      inset 0 0 10px rgba(255, 215, 0, 0.5),
      0 0 5px rgba(212, 175, 55, 0.5);
  }
  50% {
    box-shadow: 
      inset 0 0 15px rgba(255, 215, 0, 0.7),
      0 0 10px rgba(212, 175, 55, 0.7);
  }
  100% {
    box-shadow: 
      inset 0 0 10px rgba(255, 215, 0, 0.5),
      0 0 5px rgba(212, 175, 55, 0.5);
  }
}

.page-nav-button {
  padding: 10px;
  background-color: #4a515d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-nav-button:hover {
  background-color: #5a616d;
}

.page-nav-button:disabled {
  background-color: #2a3039;
  cursor: not-allowed;
}


.toolbar-options {
  border-top: 1px solid #e5e7eb;
  padding-top: 1rem;
}

.toolbar-option {
  margin-bottom: 0.75rem;
}

.toolbar-option:last-child {
  margin-bottom: 0;
}

.form-checkbox {
  border-radius: 0.25rem;
  border-color: #d1d5db;
}

.form-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #e0e0e0;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.modal-body {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.left-column {
  width: 300px;
  padding: 24px;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
}

.right-column {
  flex: 1;
  padding: 24px 0 24px 24px;
  overflow: hidden;
}

.title-section {
  display: flex;
  flex-direction: column;
}

.main-title-container,
.subtitles-container,
.shape-labels-container {
  margin-bottom: 24px;
}

.title-label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 8px;
}

.title-input,
.subtitle-input {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
  resize: vertical;
  min-height: 60px;
  max-height: 120px;
}

.subtitle-input-container,
.shape-label-input-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.subtitle-input {
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 0;
}

.shape-number {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-right: 8px;
  min-width: 20px;
}

.shape-label-input {
  flex-grow: 1;
  padding: 8px 12px;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.remove-subtitle-btn,
.add-subtitle-btn {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-subtitle-btn {
  align-self: flex-start;
  margin-top: 8px;
}

.add-subtitle-btn {
  margin-top: 8px;
}

.preview-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pdf-preview {
  width: 100%;
  height: 100%;
  border: none;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid #e0e0e0;
  gap: 16px;
}

.options-dropdown {
  position: relative;
  margin-right: auto;
}

.options-toggle {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.options-menu {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  min-width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.radio-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.radio-label input {
  margin-right: 8px;
}

.decline-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Arial', sans-serif;
  color: #fdfdfd;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4a515d;
  color: #333;
}

.accept-button {
  padding: 10px 20px;
  background-color: #6bfa58;
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Arial', sans-serif;
  color: #fdfdfd;
  display: flex;
  align-items: center;
  gap: 8px;
}

.download-icon {
  font-size: 16px;
}

.search-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
  background-color: #2f3640;
  height: 40px;
  border-radius: 40px;
  padding: 10px;
  transition: 0.4s;
  align-items: center;
}

.search-input {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
}

.search-btn {
  color: #e84118;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2f3640;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  cursor: pointer;
  text-decoration: none;
}

.search-container.active > .search-input,
.search-container:hover > .search-input {
  width: 240px;
  padding: 0 6px;
}

.search-container.active > .search-btn,
.search-container:hover > .search-btn {
  background: white;
}

.search-icon {
  font-size: 18px;
  color: #e84118;
}

.search-container.active .search-icon,
.search-container:hover .search-icon {
  color: #2f3640;
}

.upscaling-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1002;
}

.upscaling-image .spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.api-usage-section {
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #1f2937;
}

.api-usage-section table {
  width: 100%;
  position: relative;
}

/* Custom scrollbar styles */
.api-usage-section::-webkit-scrollbar {
  width: 8px;
}

.api-usage-section::-webkit-scrollbar-track {
  background: #111827;
  border-radius: 4px;
}

.api-usage-section::-webkit-scrollbar-thumb {
  background: #374151;
  border-radius: 4px;
}

.api-usage-section::-webkit-scrollbar-thumb:hover {
  background: #4B5563;
}

/* Ensure the header stays fixed */
.api-usage-section thead {
  position: sticky;
  top: 0;
  background-color: #1f2937;
  z-index: 1;
}

/* API Documentation Layout */
.api-documentation-wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* Sidebar Navigation */
.api-documentation-wrapper nav {
  width: 16rem;
  background-color: #f3f4f6;
  padding: 2rem;
  overflow-y: auto;
  flex-shrink: 0;
  border-right: 1px solid #e5e7eb;
}

/* Main Content Area */
.api-documentation-wrapper main {
  flex-grow: 1;
  padding: 2rem;
  overflow-y: auto;
  background-color: #ffffff;
}

/* Content Container */
.api-documentation-wrapper .max-w-4xl {
  margin: 0 auto;
}

/* Section Spacing */
.api-documentation-wrapper section {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e5e7eb;
}

/* Code Blocks */
.api-documentation-wrapper pre {
  margin: 1rem 0;
  border-radius: 0.5rem;
  background-color: #1a1a1a !important;
}

/* Navigation Links */
.api-documentation-wrapper nav a {
  display: block;
  padding: 0.5rem 0;
  color: #4b5563;
  text-decoration: none;
  transition: color 0.2s;
}

.api-documentation-wrapper nav a:hover {
  color: #2563eb;
}

/* Back Link */
.api-documentation-wrapper .back-link {
  display: inline-flex;
  align-items: center;
  color: #4b5563;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  text-decoration: none;
}

.api-documentation-wrapper .back-link:hover {
  color: #2563eb;
}

/* Typography */
.api-documentation-wrapper h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #111827;
}

.api-documentation-wrapper h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1f2937;
}

.api-documentation-wrapper p {
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #4b5563;
}

/* Custom Scrollbar */
.api-documentation-wrapper nav::-webkit-scrollbar,
.api-documentation-wrapper main::-webkit-scrollbar {
  width: 6px;
}

.api-documentation-wrapper nav::-webkit-scrollbar-track,
.api-documentation-wrapper main::-webkit-scrollbar-track {
  background: #f3f4f6;
}

.api-documentation-wrapper nav::-webkit-scrollbar-thumb,
.api-documentation-wrapper main::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 3px;
}

.api-documentation-wrapper nav::-webkit-scrollbar-thumb:hover,
.api-documentation-wrapper main::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

/* Code Syntax Highlighting Container */
.api-documentation-wrapper .syntax-highlighter {
  margin: 1rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
}

/* List Styles */
.api-documentation-wrapper ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.api-documentation-wrapper ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .api-documentation-wrapper {
    flex-direction: column;
  }

  .api-documentation-wrapper nav {
    width: 100%;
    max-height: 200px;
  }

  .api-documentation-wrapper main {
    padding: 1rem;
  }
}

.context-menu {
  position: fixed;
  background-color: #111827;
  border: 2px solid #3a4049;
  border-radius: 5px;
  padding: 8px 0;
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.context-menu-item {
  padding: 10px 20px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  transition: all 0.3s ease;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.context-menu-item:hover {
  background-color: #4a515d;
}

.context-menu-item svg {
  margin-right: 8px;
  width: 16px;
}

.context-menu-divider {
  height: 1px;
  background-color: #3a4049;
  margin: 4px 0;
}

.rotation-wheel {
  position: absolute;
  pointer-events: none;
  transform-origin: center;
}

.wheel-container {
  overflow: visible;
  pointer-events: none;
}

.wheel {
  background: white;
  border: 2px solid #333;
  border-radius: 50%;
  cursor: grab;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
}

.compass-arrow {
  position: absolute;
  width: 2px;
  height: 50%;
  background: #ff4444;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  transform-origin: bottom;
  pointer-events: none;
}

.compass-n {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  font-weight: bold;
  pointer-events: none;
  user-select: none;
}

.compass-marks {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.compass-marks::before,
.compass-marks::after {
  content: '';
  position: absolute;
  background: #999;
  left: 50%;
  top: 50%;
}

.compass-marks::before {
  width: 100%;
  height: 1px;
  transform: translateX(-50%);
}

.compass-marks::after {
  width: 1px;
  height: 100%;
  transform: translateY(-50%);
}

.wheel:active {
  cursor: grabbing;
}

.pointer-arrow {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  transform-origin: center;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #2563eb;
  transition: transform 0.1s ease-out;
}

.pointer-arrow::after {
  content: '';
  position: absolute;
  top: 8px;
  left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #2563eb;
  transform: scale(0.4);
}

.shape-labels-container {
  margin-top: 16px;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.title-label {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}

.shape-label-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
}

.shape-label-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.shape-label-input:focus {
  border-color: #4a90e2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}