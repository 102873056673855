.billing-management {
  padding: 1.5rem;
  background-color: #1f2937;
  border-radius: 0.75rem;
  color: #f3f4f6;
}

.billing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #374151;
}

.billing-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #f3f4f6;
}

.billing-actions {
  display: flex;
  gap: 0.75rem;
}

.billing-actions button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
}

.refresh-button {
  background-color: #374151;
  color: #f3f4f6;
  border: 1px solid #4b5563;
}

.refresh-button:hover {
  background-color: #4b5563;
}

.download-button {
  background-color: #4338ca;
  color: #f3f4f6;
  border: none;
}

.download-button:hover {
  background-color: #4f46e5;
}

.download-button:disabled {
  background-color: #4338ca80;
  cursor: not-allowed;
  opacity: 0.7;
}

.download-button:disabled:hover {
  background-color: #4338ca80;
}

.billing-card {
  background-color: #111827;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #374151;
}

.billing-card h3 {
  color: #f3f4f6;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.billing-details .detail-row {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #374151;
  color: #d1d5db;
}

.billing-details .total {
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px solid #374151;
  color: #f3f4f6;
}

.next-billing {
  margin-top: 1.25rem;
  padding: 1rem;
  background-color: #1f2937;
  border-radius: 0.5rem;
  text-align: center;
  color: #9ca3af;
  font-size: 0.875rem;
}

.api-usage-chart {
  height: 400px;
  padding: 1rem;
}

.api-usage-chart .recharts-wrapper {
  background-color: #111827;
}

.api-usage-chart .recharts-cartesian-grid line {
  stroke: #374151;
}

.api-usage-chart .recharts-text {
  fill: #9ca3af;
}

.api-usage-chart .recharts-legend-item-text {
  color: #d1d5db !important;
}

.error-container {
  padding: 1.5rem;
  background-color: #1f2937;
  border-radius: 0.75rem;
  border: 1px solid #dc2626;
}

.error-container h3 {
  color: #dc2626;
  margin-bottom: 0.75rem;
}

.loading-spinner {
  text-align: center;
  padding: 2rem;
  color: #9ca3af;
}

.billing-history {
  margin-top: 2rem;
}

.billing-history table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.billing-history th {
  text-align: left;
  padding: 1rem;
  background-color: #1f2937;
  color: #9ca3af;
  font-weight: 500;
}

.billing-history td {
  padding: 1rem;
  border-bottom: 1px solid #374151;
  color: #d1d5db;
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-badge.paid {
  background-color: #065f46;
  color: #d1fae5;
}

.status-badge.pending {
  background-color: #92400e;
  color: #fef3c7;
} 